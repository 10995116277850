import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  updateDoc,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firestore = inject(Firestore);

  getCollection(path: string) {
    return collectionData(collection(this.firestore, path));
  }

  addDoc(path: string, data: object) {
    return addDoc(collection(this.firestore, path), data);
  }

  getDoc(path: string) {
    return getDoc(doc(this.firestore, path));
  }

  updateDoc(path: string, data: any) {
    return updateDoc(doc(this.firestore, path), data);
  }

  deleteDoc(path: string) {
    return deleteDoc(doc(this.firestore, path));
  }
}
